<h1 class="text-2xl font-extrabold text-titleColor text-center">
  {{ "auth.changePassword.title" | translate }}
</h1>
<form [formGroup]="changePasswordForm" method="dialog">
  <div class="form-control pt-6">
    <input
      type="email"
      [placeholder]="'common.email' | translate"
      class="input input-bordered focus:outline-0 focus:border-black bg-white"
      required
      formControlName="email"
      autocomplete="email"
      [class.input-error]="f.email.invalid && f.email.touched"
    />
    <div class="label">
      <span class="label-text-alt text-error">
        @if (changePasswordForm.get('email')?.touched) { @if
        (changePasswordForm.get('email')?.errors?.['required']) {
        {{ "common.required" | translate }} } @if
        (changePasswordForm.get('email')?.errors?.['email']) {
        {{ "auth.changePassword.invalidEmail" | translate }} } }
      </span>
    </div>
  </div>
  <div class="form-control py-4">
    <input
      type="password"
      [placeholder]="'common.password' | translate"
      class="input input-bordered focus:outline-0 focus:border-black bg-white"
      formControlName="password"
      autocomplete="new-password"
      required
      [class.input-error]="f.password.invalid && f.password.touched"
    />

    <div class="label">
      <span class="label-text-alt">
        <span
          [ngClass]="
            f.password.hasError('required') || f.password.hasError('minlength')
              ? 'text-error'
              : 'text-success'
          "
        >
          {{ "auth.changePassword.passwordValidator.length" | translate }}
        </span>
        <br />

        <span
          [ngClass]="
            f.password.hasError('required') ||
            f.password.hasError('hasCapitalCase')
              ? 'text-error'
              : 'text-success'
          "
        >
          {{ "auth.changePassword.passwordValidator.majuscule" | translate }}
        </span>
        <br />

        <span
          [ngClass]="
            f.password.hasError('required') || f.password.hasError('hasNumber')
              ? 'text-error'
              : 'text-success'
          "
        >
          {{ "auth.changePassword.passwordValidator.number" | translate }}
        </span>
        <br />

        <span
          [ngClass]="
            f.password.hasError('required') ||
            f.password.hasError('hasSpecialCharacters')
              ? 'text-error'
              : 'text-success'
          "
        >
          {{ "auth.changePassword.passwordValidator.specialChar" | translate }}
        </span>
      </span>
    </div>
  </div>
  <div class="form-control pb-6">
    <input
      type="password"
      [placeholder]="'common.confirmPassword' | translate"
      class="input input-bordered focus:outline-0 focus:border-black bg-white"
      required
      formControlName="confirmPassword"
      autocomplete="new-password"
      [class.input-error]="
        f.confirmPassword.invalid && f.confirmPassword.touched
      "
    />
    <div class="label">
      <span class="label-text-alt text-error">
        @if (changePasswordForm.get('confirmPassword')?.touched) { @if
        (changePasswordForm.get('confirmPassword')?.errors?.['required']) {
        {{ "common.required" | translate }} } @if
        (changePasswordForm.get('confirmPassword')?.errors?.['mustMatch']) {
        {{ "auth.changePassword.passwordNotMatch" | translate }} } }
      </span>
    </div>
  </div>
  <div class="form-control">
    <button type="submit" class="btn btn-primary text-white" (click)="onSubmit()">
      {{ "common.submit" | translate }}
    </button>
  </div>
</form>
