import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-intro',
  standalone: true,
  imports: [TranslateModule],
  template: `<h1 class="text-5xl font-bold text-white">
      {{ 'auth.login.mainTitle' | translate }}
      <span class="text-2xl">®</span>
    </h1>
    <p class="my-4 text-primary text-2xl tracking-onepx font-medium">
      {{ 'auth.login.subTitle' | translate }}
    </p>
    <p class=" text-lg text-textLight">
      {{ 'auth.login.description' | translate }}
    </p> `,
})
export class IntroComponent {}
