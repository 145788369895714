<div class="social-icons flex gap-6">
  <img
    src="assets/icons/home-outline.svg"
    class="cursor-pointer text-body"
  />
  <img
    src="assets/icons/logo-facebook.svg"
    (click)="target.emit('facebook')"
    class="cursor-pointer text-body"
  />

  <img
    src="assets/icons/logo-youtube.svg"
    (click)="target.emit('youtube')"
    class="cursor-pointer text-body"
  />
  <img
    src="assets/icons/logo-instagram.svg"
    (click)="target.emit('instagram')"
    class="cursor-pointer text-body"
  />
  <img
    src="assets/icons/mail-outline.svg"
    (click)="target.emit('mail')"
    class="cursor-pointer text-body"
  />
</div>

<!--
convert hex color for fill svg
https://codepen.io/sosuke/pen/Pjoqqp

-->
