import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { UsersService } from '../services/users.service';

export const profileResolver: ResolveFn<any> = (
  route,
  state,
  userService = inject(UsersService),
) => {
  return userService.myData();
};
