import { Component, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-copyright-footer',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <div class="small-footer px-10 md:px-0 text-center text-sm">
      {{ 'auth.footer.firstPart' | translate }}
      <span class="cursor-pointer text-primary" (click)="dhreamCorp.emit()"
        >{{ 'auth.footer.btn' | translate }}
      </span>

      {{ 'auth.footer.lastPart' | translate }}
    </div>
  `,
})
export class CopyrightFooterComponent {
  dhreamCorp = output<void>();
}
