<div
  class="min-h-screen flex justify-center items-center md:items-start md:pt-40"
>
  <div class="flex flex-col">
    <div class="flex flex-col-reverse md:flex-row content-center">
      <div
        class="w-login bg-login md:rounded-tl-2xl md:rounded-bl-2xl shadow-lg hidden md:block p-11"
        style="padding: 4.5rem"
      >
        <app-intro />
      </div>
      <div
        class="w-login bg-white p-20 md:rounded-tr-2xl md:rounded-br-2xl shadow-lg flex flex-col justify-center"
      >
        @if (formType$$() === "reset") {
        <app-reset-form
          (submit)="resetPassword($event)"
          (back)="cancelReset()"
        />
        } @else if(formType$$() === "login"){
        <app-login-form
          [attempt]="passwordAttempt$$()"
          (submit)="login($event)"
          (resetPassword)="showResetPasswordComponent()"
          [loginState]="loginState()"
        />
        } @else {
        <app-change-password-form
          [email]="email$$()"
          (submitChangePassword)="changePassword($event)"
        />
        }
      </div>
    </div>
    <div
      class="flex flex-col gap-4 md:flex-row mt-8 items-center justify-center md:justify-between"
    >
      <app-social-icon-footer (target)="navigateTo($event)" />
      <app-copyright-footer (dhreamCorp)="navigateTo()" />
    </div>
    <div class="flex justify-center w-full mt-3">
      <app-privacy-policy-footer (target)="navigateTo($event)" />
    </div>
  </div>
</div>
