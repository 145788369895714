import {
  Component,
  inject,
  input,
  output,
  signal,
  effect,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthType } from '../../../../core/models/auth-type.model';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent {
  #fb = inject(FormBuilder);

  attempt = input<number>();
  submit = output<AuthType>();
  resetPassword = output<void>();

  submitClicked = signal(false);

  loginState = input<'success' | 'failed' | ''>('');

  loginstate = effect(
    () => {
      if (this.loginState() === 'failed' || this.loginState() === 'success') {
        this.submitClicked.set(false);
      }
    },
    {
      allowSignalWrites: true,
    },
  );

  loginForm = this.#fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  onSubmit() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid && !this.submitClicked()) {
      this.submitClicked.set(true);
      this.submit.emit(this.loginForm.value as AuthType);
      this.loginForm.reset();
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onResetPassword() {
    this.loginForm.reset();
    this.resetPassword.emit();
  }
}
