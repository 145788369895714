import { Component, inject, output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ResetPasswordType } from '../../../../core/models/auth-type.model';

@Component({
  selector: 'app-reset-form',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule],
  templateUrl: './reset-form.component.html',
})
export class ResetFormComponent {
  #fb = inject(FormBuilder);
  submit = output<ResetPasswordType>();
  back = output<void>();

  resetForm = this.#fb.group({
    username: ['', Validators.required],
  });

  onSubmit() {
    this.resetForm.markAllAsTouched();
    if (this.resetForm.valid) {
      this.submit.emit(this.resetForm.value as ResetPasswordType);
      this.resetForm.reset();
    }
  }

  onCancel() {
    this.back.emit();
    this.resetForm.reset();
  }

  get f() {
    return this.resetForm.controls;
  }
}
