<h1 class="text-2xl font-extrabold text-titleColor text-center transition mb-6">
  @if (attempt() === 5) {
  <span [innerHTML]="'auth.login.loginTitle' | translate"> </span>

  } @else {
  {{ "auth.login.error.title" | translate }}
  }
</h1>
<p
  class="mb-6 text-center px-6 text-body text-base leading-normal transition rounded-md"
  [class.bg-error]="attempt()! < 5"
  [class.py-4]="attempt()! < 5"
>
  @if (attempt() === 5) {
  <span [innerHTML]="'auth.login.info' | translate"> </span>
  } @else {
  <span
    [innerHTML]="
      'auth.login.error.message' | translate : { attempt: attempt() }
    "
  ></span>
  }
</p>

<form [formGroup]="loginForm" (keyup.enter)="onSubmit()">
  <div class="form-control">
    <input
      type="text"
      [placeholder]="'common.username' | translate"
      class="input input-bordered bg-white focus:outline-0 focus:border-black"
      required
      formControlName="username"
      [class.input-error]="f.username.invalid && f.username.touched"
    />

    <div class="label">
      <span class="label-text-alt text-error">
        @if (loginForm.get('username')?.touched) { @if
        (loginForm.get('username')?.errors?.['required']) {
        {{ "common.required" | translate }} }}
      </span>
    </div>
  </div>
  <div class="form-control py-3">
    <input
      type="password"
      [placeholder]="'common.password' | translate"
      class="input input-bordered bg-white focus:outline-0 focus:border-black"
      required
      autocomplete="on"
      formControlName="password"
      [class.input-error]="f.password.invalid && f.password.touched"
    />

    <div class="label">
      <span class="label-text-alt text-error">
        @if (loginForm.get('password')?.touched) { @if
        (loginForm.get('password')?.errors?.['required']) {
        {{ "common.required" | translate }} }}
      </span>
    </div>
  </div>
</form>
<div class="form-control">
  <button type="submit" class="btn btn-primary text-xl text-white" (click)="onSubmit()">
    @if (submitClicked()) {
      <span class="loading loading-spinner loading-lg"></span>
    } @else {
    {{ "common.submit" | translate }}
    }

  </button>
</div>
<label class="label italic text-center text-sm py-4">
  <span>
    {{ "auth.login.clickHereTo" | translate }}
    <span class="text-primary cursor-pointer" (click)="onResetPassword()">
      {{ "auth.login.resetPassword" | translate }}</span
    ></span
  >
</label>
