import { inject } from '@angular/core';
import { TokenService } from './../../services/token.service';
import { HttpInterceptorFn } from '@angular/common/http';
import { LoginResponse } from '../../models/login-response.model';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const userLogin: LoginResponse = JSON.parse(
    tokenService.getActiveToken() ?? '{}',
  );

  if (userLogin.token) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${userLogin.token}`,
      },
    });
  }
  return next(req);
};
