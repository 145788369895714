import { Component, output } from '@angular/core';
import { NavigateToTarget } from '../../../../core/models/navigate-to-target.model';

@Component({
  selector: 'app-privacy-policy-footer',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy-footer.component.html',
  styleUrl: './privacy-policy-footer.component.scss',
})
export class PrivacyPolicyFooterComponent {
  target = output<NavigateToTarget>();
}
