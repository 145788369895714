import { LastProjectSelectedService } from './../services/last-project-selected.service';
import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

export const favoriteProjectResolver: ResolveFn<any> = (
  route,
  state,
  lastProjectSelectedService = inject(LastProjectSelectedService),
) => {
  return lastProjectSelectedService.getFavProjectId().pipe().subscribe();
};
