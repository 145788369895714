import {
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { authInterceptor } from './core/security/interceptors/auth.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { errorHandlerInterceptor } from './core/security/interceptors/error-handler.interceptor';
import { successHandlerInterceptor } from './core/security/interceptors/success-handler.interceptor';

const globalRippleConfig: RippleGlobalOptions = {
  animation: {
    enterDuration: 250,
    exitDuration: 300,
  },
};
export const appConfig: ApplicationConfig = {
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        errorHandlerInterceptor,
        successHandlerInterceptor,
      ]),
    ),
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'fr',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideAnimationsAsync(),
    provideAnimationsAsync('animations'),
  ],
};
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
