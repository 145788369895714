import { Routes } from '@angular/router';

import { isLoggedGuard } from './core/security/guards/is-logged.guard';
import { IsNotLoggedGuard } from './core/security/guards/is-not-logged.guard';
import { TestUiComponent } from './features/test-ui/test-ui.component';
import { AuthComponent } from './features/auth/auth.component';
import { favoriteProjectResolver } from './core/resolvers/favproject.resolver';
import { profileResolver } from './core/resolvers/profile.resolver';

export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [IsNotLoggedGuard],
  },
  {
    path: 'nouveau-mot-de-passe/:email/:token',
    component: AuthComponent,
  },
  {
    path: 'home',
    resolve: [favoriteProjectResolver],
    loadComponent: () =>
      import('./features/home/home.component').then((m) => m.HomeComponent),
    canActivate: [isLoggedGuard],
    children: [
      {
        path: '',
        redirectTo: 'lignee',
        pathMatch: 'full',
      },
      {
        path: 'lignee',
        loadComponent: () =>
          import('./features/line-list/line-list.component').then(
            (m) => m.LineListComponent,
          ),
      },
      {
        path: 'stats',
        loadComponent: () =>
          import('./features/stats/stats.component').then(
            (m) => m.StatsComponent,
          ),
      },
      {
        path: 'list',
        loadComponent: () =>
          import('./features/lists/lists.component').then(
            (m) => m.ListsComponent,
          ),
      },
      {
        path: 'dmp',
        loadComponent: () =>
          import('./features/dmp/dmp.component').then((m) => m.DmpComponent),
      },
      {
        path: 'permissions',
        loadComponent: () =>
          import(
            './features/project-admin-pannel/components/permissions/permissions.component'
          ).then((m) => m.PermissionsComponent),
      },
      {
        path: 'administration',
        loadComponent: () =>
          import('./features/project-admin-pannel/admin-panel.component').then(
            (m) => m.ProjectAdminPannelComponent,
          ),
      },
      {
        path: 'profile',
        resolve: {
          profile: profileResolver,
        },
        loadComponent: () =>
          import('./features/profile/profile.component').then(
            (m) => m.ProfileComponent,
          ),
      },
    ],
  },

  {
    path: 'test-ui',
    component: TestUiComponent,
  },
  { path: '**', redirectTo: '' },
];
