import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { SuccessHandlerService } from '../../services/successHandler.service';

export const successHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const service = inject(SuccessHandlerService);

  return next(req).pipe(
    tap((event: any) => {
      if (event instanceof HttpResponse && [201].includes(event.status)) {
        service.handleSuccess(event.body?.message);
      }
    }),
  );
};
