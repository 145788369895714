import { MatDialog } from '@angular/material/dialog';
import { Component, inject, OnInit } from '@angular/core';
import { AddSkipperFormComponent } from '../../shared/ui-components/add-skipper-form/add-skipper-form.component';
import { FiltersComponent } from '../../shared/ui-components/filters/filters.component';
import { UpdateProjectModalComponent } from '../project-admin-pannel/components/update-project-modal/update-project-modal.component';

@Component({
  selector: 'app-test-ui',
  standalone: true,
  imports: [AddSkipperFormComponent, FiltersComponent],
  templateUrl: './test-ui.component.html',
  styleUrl: './test-ui.component.scss',
})
export class TestUiComponent implements OnInit {
  dialog = inject(MatDialog);

  //https://drheamapi.illisite.info/api/uvcs/253428/modal

  ngOnInit(): void {
    const dialogref = this.dialog.open(UpdateProjectModalComponent, {
      data: { projectId: 96 },
      maxWidth: '90vw',
      autoFocus: false,
    });
  }
}
