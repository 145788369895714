import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { catchError, of } from 'rxjs';
import { LoaderService } from '../../services/loader.service';

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const service = inject(ErrorHandlerService);
  const loader = inject(LoaderService);
  if (req.url.includes('api/login') || req.url.includes('NaN')) {
    return next(req);
  }

  return next(req).pipe(
    catchError((err) => {
      if (loader.isLoading()) {
        loader.stopLoading();
      }
      service.handleError(err.error.message);
      return of(err);
    }),
  );
};
