import { Component, OnInit, inject, signal } from '@angular/core';

import { LoginFormComponent } from './component/login-form/login-form.component';

import { ResetFormComponent } from './component/reset-form/reset-form.component';
import { CopyrightFooterComponent } from './component/copyright-footer/copyright-footer.component';
import { SocialIconFooterComponent } from './component/social-icon-footer/social-icon-footer.component';
import { IntroComponent } from './component/intro/intro.component';
import { ModalChangePasswordComponent } from './component/change-password-form/change-password-form.component';
import { catchError, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';
import { PrivacyPolicyFooterComponent } from './component/privacy-policy-footer/privacy-policy-footer.component';
import { AuthService } from '../../core/services/auth.service';
import {
  AuthType,
  ChangePasswordType,
  ResetPasswordType,
} from '../../core/models/auth-type.model';
import { NavigateToTarget } from '../../core/models/navigate-to-target.model';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    LoginFormComponent,
    IntroComponent,
    ResetFormComponent,
    CopyrightFooterComponent,
    SocialIconFooterComponent,
    ModalChangePasswordComponent,
    PrivacyPolicyFooterComponent,
  ],
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
  #authService = inject(AuthService);
  #activatedRoute = inject(ActivatedRoute);

  loginState = signal<'success'|'failed'|''>('')

  passwordAttempt$$ = signal(5);
  tokenReset$$ = signal('');
  formType$$ = signal<'login' | 'reset' | 'change'>('login');

  email$$ = signal('');

  ngOnInit(): void {
    const path = this.#activatedRoute.snapshot.url
      .map((segment) => segment.path)
      .join('/');
    const email = this.#activatedRoute.snapshot.paramMap.get('email') ?? '';
    const token = this.#activatedRoute.snapshot.paramMap.get('token') ?? '';

    if (path.startsWith('nouveau-mot-de-passe') && email && token) {
      this.email$$.set(email);
      this.tokenReset$$.set(token);
      this.formType$$.set('change');
    }
  }

  // decrementedPasswordAttempt() {
  //   if (this.passwordAttempt$$() === 1) {
  //     this.showResetPasswordComponent();
  //   }
  //   this.passwordAttempt$$.set(this.passwordAttempt$$() - 1);
  // }

  login(authValues: AuthType) {
    this.#authService
      .login(authValues)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 302) {
            this.tokenReset$$.set(error.error.token_reset);
            this.formType$$.set('change');
          }
          if (error.error.failed_login_attempts !== undefined) {
            this.passwordAttempt$$.set(error.error.failed_login_attempts);
          }
          this.loginState.set('failed');
          return of();
        }),
        tap(()=>{
          this.loginState.set('success');
        })
      )
      .subscribe((x) => {

      });
  }

  resetPassword(evt: ResetPasswordType) {
    this.#authService.forgotPassword(evt).subscribe();
  }

  showResetPasswordComponent() {
    this.formType$$.set('reset');
  }

  changePassword(value: ChangePasswordType) {
    value.token = this.tokenReset$$();

    if (
      value.email !== undefined &&
      value.password !== undefined &&
      value.confirmPassword !== undefined &&
      value.token !== undefined
    ) {
      this.#authService.changePassword(value).subscribe();
    }
  }

  cancelReset() {
    this.passwordAttempt$$.set(3);
    this.formType$$.set('login');
  }

  navigateTo(target?: NavigateToTarget) {
    let url;
    switch (target) {
      case 'home': {
        url = '#';
        break;
      }
      case 'facebook': {
        url = 'https://www.facebook.com/search/top?q=drheam';
        break;
      }
      case 'instagram': {
        url = 'https://www.instagram.com/drheamcorp/';
        break;
      }
      case 'youtube': {
        url = 'https://www.youtube.com/@drheam6686';
        break;
      }
      case 'mail': {
        url = 'mailto:contact@drheam.fr';
        break;
      }
      default: {
        url = 'https://drheam.fr/';
        break;
      }
    }

    window.open(url, '_blank');
  }
}
