<style>
  .loader-backdrop {
    position: fixed; /* Fixed pour couvrir toute la fenêtre */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
</style>

<div class="loader-backdrop">
  <div class="loader w-16 h-16">
    <span class="loading loading-spinner text-primary w-full h-full"></span>
  </div>
</div>
