import {
  Component,
  OnInit,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { ChangePasswordType } from '../../../../core/models/auth-type.model';

@Component({
  selector: 'app-change-password-form',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgIf, NgClass, JsonPipe],
  templateUrl: './change-password-form.component.html',
})
export class ModalChangePasswordComponent implements OnInit {
  #fb = inject(FormBuilder);
  submitChangePassword = output<ChangePasswordType>();

  email = input<string>();

  passwordMatch = signal(false);

  changePasswordForm = this.#fb.group(
    {
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          this.#patternValidator(/\d/, { hasNumber: true }),
          this.#patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          this.#patternValidator(/[!@#$%^&*(),.?":{}|<>]/, {
            hasSpecialCharacters: true,
          }),
        ]),
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      validators: this.#mustMatch('password', 'confirmPassword'),
    } as AbstractControlOptions,
  );

  ngOnInit(): void {
    this.changePasswordForm.get('email')?.setValue(this.email() ?? '');
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  onSubmit() {
    this.changePasswordForm.markAllAsTouched();
    if (this.changePasswordForm.valid) {
      this.submitChangePassword.emit(
        this.changePasswordForm.value as ChangePasswordType,
      );
      this.changePasswordForm.reset();
    }
  }

  #mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  #patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
}
