<h1
  class="text-2xl font-extrabold text-titleColor text-center"
  [innerHTML]="'auth.reset.resetTitle' | translate"
></h1>
<p class="py-6 text-center text-body text-base leading-normal" [innerHTML]='"auth.reset.info" | translate'>


</p>
<form [formGroup]="resetForm" (keyup.enter)="onSubmit()">
  <div class="form-control">
    <input
      type="text"
      [placeholder]="'common.username' | translate"
      class="input input-bordered focus:outline-0 focus:border-black bg-white"
      required
      formControlName="username"
      [class.input-error]="f.username.invalid && f.username.touched"
    />

    <div class="label">
      <span class="label-text-alt text-error">
        @if (resetForm.get('username')?.touched) { @if
        (resetForm.get('username')?.errors?.['required']) {
        {{ "common.required" | translate }} }}
      </span>
    </div>
  </div>
</form>
<div class="form-control flex flex-row w-full gap-2 my-4">
  <button
    type="submit"
    class="btn btn-outline btn-error w-1/2"
    (click)="onCancel()"
  >
    {{ "common.cancel" | translate }}
  </button>
  <button type="submit" class="btn btn-primary w-1/2 text-white" (click)="onSubmit()">
    {{ "common.submit" | translate }}
  </button>
</div>
