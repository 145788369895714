import { Component, output } from '@angular/core';
import { NavigateToTarget } from '../../../../core/models/navigate-to-target.model';

@Component({
  selector: 'app-social-icon-footer',
  standalone: true,
  imports: [],
  templateUrl: './social-icon-footer.component.html',
  styles: [
    `
      img {
        width: 16px;
      }
      .text-body {
        filter: invert(26%) sepia(4%) saturate(2097%) hue-rotate(184deg)
          brightness(93%) contrast(89%);
      }

      .text-primary {
        filter: invert(60%) sepia(43%) saturate(2374%) hue-rotate(189deg)
          brightness(102%) contrast(96%);
      }
    `,
  ],
})
export class SocialIconFooterComponent {
  target = output<NavigateToTarget>();
}
